import React from 'react'

if (process.env.REACT_APP_NODE_ENV === 'development') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/no-shadow
    const whyDidYouRender = require('@welldone-software/why-did-you-render')

    whyDidYouRender(React, {
        // collapseGroups: true,
        // include: [/.*/],
        // logOnDifferentValues: false,
        // trackAllPureComponents: true,
        trackHooks: true,
    })
}

import React, { Suspense } from 'react'

// import * as Sentry from '@sentry/react'
// import { BrowserTracing } from '@sentry/tracing'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { store } from 'store'
import 'simplebar-react/dist/simplebar.min.css'
import 'react-datepicker/dist/react-datepicker.css'

import App from './App'
import './i18n'
import './index.css'
import './wdyr'
import reportWebVitals from './reportWebVitals'

// Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_DSN,
//     integrations: [new BrowserTracing()],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// })

const renderApp = async () => {
    const LDProvider = await asyncWithLDProvider({ clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID })

    const container = document.getElementById('root')
    const root = createRoot(container!)

    root.render(
        <Suspense fallback={null}>
            <LDProvider>
                <Provider store={store}>
                    <App />
                </Provider>
            </LDProvider>
        </Suspense>,
    )
}

;(async () => {
    await renderApp()
})()

reportWebVitals()

import { gql } from '@apollo/client'

import { MAIN_EMPLOYEE } from 'graphql/dataQuery'

export const CALENDAR_EVENT = gql`
    ${MAIN_EMPLOYEE}
    fragment CalendarEventFullFields on CalendarEvents {
        id
        startTime
        endTime
        reservableID
        organiserEmployeeID
        title
        description

        OrganiserEmployee {
            ...MainEmployeeFields
        }

        Employees {
            items {
                Employee {
                    ...MainEmployeeFields
                }
            }
        }
        Catering {
            id
            enabled
            title
        }
        menuItems {
            id
            description
            title
            quantity
        }
    }
`

export const CALENDAR_EVENT_REQUEST = gql`
    ${MAIN_EMPLOYEE}
    fragment CalendarEventRequestFields on CalendarEventRequest {
        id
        startTime
        endTime
        reservableID
        organiserEmployeeID
        title
        description
        status
        repeatType
        dates
        daysOfWeek

        OrganiserEmployee {
            ...MainEmployeeFields
        }

        Employees {
            items {
                Employee {
                    ...MainEmployeeFields
                }
            }
        }

        Catering {
            id
            enabled
            title
        }

        menuItems {
            id
            description
            title
            quantity
        }
    }
`

import { RepeatType } from 'components/organisms/SeatPicker/components/SeatBookingPanel/types'
import { ERoleEmployee, TPosition } from 'types/data.types'

import type { Space, MenuItemModel } from 'graphql/autogenerate/schemas'

export enum EventTypes {
    BOOKING = 'BOOKING',
    TEAM_EVENT = 'TEAM_EVENT',
    ROOM_EVENT = 'ROOM_EVENT',
    PARKING_BOOKING = 'PARKING_BOOKING',
    ZONE_BOOKING = 'ZONE_BOOKING',
}

export enum BookingEventTypeMode {
    EDIT = 'edit',
    CREATE = 'create',
    INFO = 'info',
}

export type EmployeeData = {
    id: string
    fullName: string
    photo: string
    firstname: string
    lastname: string
    departmentIDs: Array<string>
    favouriteColleagueIDs?: Array<string>
    Position?: Pick<TPosition, 'name'>
    role?: ERoleEmployee
}

export type BookingType = {
    id: string
    startTime: string
    endTime: string
    Employee: EmployeeData
    bookingRequestID?: string
    isBlocked?: boolean
    BookingRequest?: {
        id
        addParkingSlot: boolean
        ParkingSlot?: {
            id: string
            name: string
            Space?: Space
        }
    }
    bookingReservableID?: string
}

export type RoomEventType = {
    id: string
    startTime: string
    endTime: string
    title: string
    description: string
    Employees: Array<EmployeeData>
    organiserEmployeeID: string
    OrganiserEmployee: EmployeeData
    isOnlineMeeting: boolean
    isPrivate: boolean
    onlineMeetingUrl: string
    menuItems: MenuItemModel[] | []
}

export type QueryRoomEventType = RoomEventType & {
    Employees: {
        items: Array<{ Employee: EmployeeData }>
    }
}

export type ExtendedBookingType = {
    id: string
    title?: string
    height: number
    start: number
    time: string
    Employee: EmployeeData
    BookingRequest?: {
        id
        addParkingSlot: boolean
        repeatType?: RepeatType
        daysOfWeek?: Array<string>
        ParkingSlot?: {
            id: string
            name: string
        }
    }
    bookingReservableID?: string
    canEdit: boolean
    isNewEvent?: boolean
    isBlocked?: boolean
}
export type ExtendedRoomEventType = {
    id: string
    title: string
    height: number
    start: number
    time: string
    description: string
    Employees: Array<EmployeeData>
    organiserEmployeeID: string
    OrganiserEmployee?: EmployeeData
    canEdit: boolean
    isNewEvent?: boolean
    isOnlineMeeting?: boolean
    onlineMeetingUrl?: string
    isPrivate?: boolean
    menuItems?: MenuItemModel[]
}

export type SelectedBookingType = BookingType & {
    date: string
    mode: BookingEventTypeMode
    title?: string
    // time?: string
}

export type SelectedRoomEventType = RoomEventType & {
    date: string
    mode: BookingEventTypeMode
    title?: string
    // time?: string
}

export type FreeTimeline = {
    start: number
    nextBookingStart: number
}

export type BookingsByDay = Record<string, Array<BookingType>>
export type RoomEventsByDay = Record<string, Array<RoomEventType>>

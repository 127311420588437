import styled, { css } from 'styled-components'

export const EmployeeItemRoot = styled.li<{ disabled?: boolean; isSelected?: boolean }>`
    list-style-type: none;
    display: flex;
    align-items: center;
    position: relative;
    padding: 15px 0;

    ${(props) =>
        props.disabled &&
        css`
            background: rgba(255, 255, 255, 0.3);
            opacity: 0.3;
        `}

    &.selected {
        .MuiAvatar-root {
            border: 2px solid #001bff;
            &::before {
                content: '';
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 1px solid #fff;
            }
        }
        h5 {
            color: #001aff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
        }
    }
`

export const EmployeeItemInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8px;
    overflow: hidden;
    //margin-top: 15px;
    //margin-bottom: 15px;
`

export const EmployeeItemTitle = styled.h5<{ isSelected: boolean }>`
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    word-break: break-all;

    ${(props) =>
        props.isSelected &&
        css`
            font-weight: 600;
        `}
`

export const EmployeeItemDepartment = styled.span`
    font-size: 12px;
    line-height: 18px;
    color: rgba(51, 51, 51, 0.5);
`

export const IconWrap = styled.span`
    position: absolute;
    padding-top: 34px;
    margin-left: 23px;
`

export const LoadingItem = styled.div<{ height: string; width: string; boderRadius: string }>`
    background: rgba(51, 51, 51, 0.05);
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    border-radius: ${(props) => props.boderRadius};
    margin-right: 16px;

    -webkit-animation: flickerAnimation 2s infinite;
    -moz-animation: flickerAnimation 2s infinite;
    -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;

    @keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.3;
        }
        100% {
            opacity: 1;
        }
    }
`

export const LoadingItemWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`

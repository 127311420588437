import moment from 'moment/moment'

import { getHorizonDay } from './rules'
import * as T from '../types/data.types'

import { getAWSFormatDate } from './index'

export const getHorizonMaxDate = (isAdmin: boolean, rules?: T.TRules): string | undefined => {
    if (!rules || isAdmin) {
        return undefined
    }

    const horizonDayAmount = getHorizonDay(rules.horizonOfPlanningTimeAmount, rules.horizonOfPlanningTimeUnit)

    return getAWSFormatDate(moment().add(horizonDayAmount, 'days'))
}

import React, { FC } from 'react'

import { useCompanyData } from 'context'
import { AlertContext } from 'context/alertContext'
import { CompanyProvider } from 'context/company'

import { CateringProvider } from '../../components/shared/widgets/Catering/context'
import Wrapper from '../components/Wrapper/Wrapper'

const EmployeeTemplateComponent: FC = () => {
    const { loading } = useCompanyData()

    return <AlertContext>{!loading && <Wrapper />}</AlertContext>
}

const EmployeeTemplate: FC = () => {
    return (
        <CompanyProvider>
            <CateringProvider>
                <EmployeeTemplateComponent />
            </CateringProvider>
        </CompanyProvider>
    )
}

export default EmployeeTemplate

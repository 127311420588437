import React, { createContext, useState, FC, ReactNode, useContext } from 'react'

import type { Catering, MenuItem } from 'graphql/autogenerate/schemas'

export interface MenuItemWithQuantity extends MenuItem {
    quantity?: number
}

interface CateringContextProps {
    currentCatering: Catering | null
    setCurrentCatering: (catering: Catering | null) => void
    selectMenuItems: MenuItemWithQuantity[] // Изменяем тип на MenuItemWithQuantity[]
    setSelectMenuItems: (menuItems: MenuItemWithQuantity[]) => void // Изменяем тип на MenuItemWithQuantity[]
}

interface CateringProviderProps {
    children: ReactNode
}

export const CateringContext = createContext<CateringContextProps | null>(null)

export const useCateringContext = () => {
    const context = useContext(CateringContext)
    if (!context) {
        throw new Error('useCateringContext must be used within a CateringProvider')
    }
    return context
}

export const CateringProvider: FC<CateringProviderProps> = ({ children }) => {
    const [currentCatering, setCurrentCatering] = useState<Catering | null>(null)

    const [selectMenuItems, setSelectMenuItems] = useState<MenuItemWithQuantity[]>([])

    return (
        <CateringContext.Provider value={{ currentCatering, setCurrentCatering, selectMenuItems, setSelectMenuItems }}>
            {children}
        </CateringContext.Provider>
    )
}

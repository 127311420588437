import { FC } from 'react'

import { Circle, Text } from 'react-konva'

import { ColorAvatarSeat } from '../types'

type Props = {
    colors: ColorAvatarSeat
    amount: string
    selected: boolean
    size: {
        width: number
        height: number
        radius: number
        avatar: number
    }
    offsetX?: number
    offsetY?: number
}

const TextBookingsAmount: FC<Props> = ({
    amount,
    selected,
    size: { width, radius, avatar },
    colors,
    offsetX,
    offsetY = 3,
}) => {
    const colorText = selected ? colors.colorSelectedText : colors.colorText
    const colorBackground = colors.colorBack
    return (
        <>
            <Circle radius={avatar} fill={colorBackground} offsetX={offsetX} offsetY={offsetY + 2} />
            <Text
                width={width}
                x={-width / 2}
                y={-radius / 2 + 2}
                align="center"
                text={amount}
                fontFamily="Poppins"
                fontSize={14}
                fontStyle="bold"
                fill={colorText}
                offsetX={offsetX}
                offsetY={offsetY}
            />
        </>
    )
}

export default TextBookingsAmount

import { FC, useRef, useState, useEffect } from 'react'

import Konva from 'konva'
import { Circle, Group, Image, Text, Rect, Path } from 'react-konva'

import { EAvailabilityType, EReservableType, TReservable, MainEmployeeData } from 'types/data.types'

import AvatarReservable from './AvatarReservable'
import TextReservable from './TextReservable'
import { PIN_SIZES, RESERVABLE_COLORS } from '../helpers/constants'
import { getInitialsDepartment } from '../helpers/getInitialsDepartment'
import { getInitialsEmployees } from '../helpers/getInitialsEmployees'
import { getHeightPinByType } from '../helpers/getSizes'
import { usePinsImages } from '../hooks/usePinsImages'
import { SelectedReservable } from '../types'

const pathData =
    'M6.16872 4.00308L5.39995 2.84992L5.39994 2.84991C4.7053 1.80795 4.35798 1.28697 3.8991 0.909816C3.49286 0.575922 3.02477 0.325407 2.52161 0.172602C1.95325 -1.18429e-06 1.32711 -1.23903e-06 0.0748243 -1.34851e-06L14.9252 -5.02526e-08C13.6729 -1.59731e-07 13.0468 -2.1447e-07 12.4784 0.172603C11.9752 0.325407 11.5071 0.575923 11.1009 0.909817C10.642 1.28697 10.2947 1.80796 9.60005 2.84992L8.83128 4.00308C8.38616 4.67077 8.16359 5.00461 7.88307 5.12094C7.63782 5.22265 7.36218 5.22265 7.11693 5.12094C6.83641 5.00461 6.61385 4.67077 6.16873 4.00309L6.16872 4.00308Z'

const Colors = {
    colorBack: 'transparent',
    colorSelectedBack: 'transparent',
    colorText: RESERVABLE_COLORS.TEXT,
    colorSelectedText: RESERVABLE_COLORS.SELECTED,
}

type Props = {
    reservable: TReservable
    selected: boolean
    onDragStart?: (e: Konva.KonvaEventObject<DragEvent>) => void
    onDragEnd?: (e: Konva.KonvaEventObject<DragEvent>) => void
    onHoverSeat?: (id: string | null) => void
    onDragMove?: (e: Konva.KonvaEventObject<DragEvent>) => void
    onSelect: (val: SelectedReservable) => void
    onHover: () => void
    onOutHover: () => void
    showNameTooltip?: boolean
    showTextSelected?: boolean
}

export const SeatEditorReservable: FC<Props> = ({
    reservable,
    selected,
    onDragEnd,
    onDragMove,
    onSelect,
    onHover,
    onOutHover,
    showNameTooltip,
    showTextSelected = true,
}) => {
    const textName = useRef<Konva.Text | null>(null)
    const refGroup = useRef<Konva.Group | null>(null)

    const [width, setWidth] = useState(0)
    const [hover, setHover] = useState(false)

    const imagePin = usePinsImages(reservable.type, reservable.availabilityType, selected)

    const heightPin = getHeightPinByType(reservable.type)
    const offsetY = reservable.type === EReservableType.PARKING ? 15 : 4

    const { name } = reservable

    const enterHoverSeat = (e: Konva.KonvaEventObject<MouseEvent>) => {
        setHover(true)
        refGroup.current?.moveToTop()
        onHover()
    }

    const outHoverSeat = (e: Konva.KonvaEventObject<MouseEvent>) => {
        setHover(false)
        onOutHover()
    }

    const handleSelect = (e: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
        const stage = e.currentTarget.getStage()

        if (stage === null) {
            return
        }

        const offset = heightPin / 2 + 5

        onSelect({
            reservable,
            posX: e.currentTarget.getAbsolutePosition().x,
            posY: e.currentTarget.getAbsolutePosition().y - offset * stage.scaleX(),
            zoom: stage.scaleX(),
        })
    }

    useEffect(() => {
        const widthText = textName.current ? textName.current?.textWidth : 0

        setWidth(widthText)
    }, [name, showNameTooltip])

    const padding = 8
    const rectX = -(width / 2 + padding)
    const rectY = -PIN_SIZES.HEIGHT_SEAT_PIN - 10

    const employeeViewText = getInitialsEmployees(reservable.ReservableToEmployees?.items || []) as null | string

    const setFirstEmployee = () => {
        if (reservable.ReservableToEmployees && (reservable.ReservableToEmployees?.items || []).length === 1) {
            return reservable.ReservableToEmployees?.items?.[0]?.employee as MainEmployeeData
        }

        return null
    }

    const firstEmployee = setFirstEmployee()

    const showAvatar = reservable.ReservableToEmployees && reservable.ReservableToEmployees?.items.length === 1

    const getTextPin = () => {
        if (!showTextSelected && selected) {
            return ' '
        } else {
            return employeeViewText
        }
    }

    return (
        <Group
            ref={refGroup}
            id={reservable.id}
            draggable
            onDragEnd={onDragEnd}
            onDragMove={onDragMove}
            x={reservable.x}
            y={reservable.y}
            scaleX={hover || selected ? 1.125 : 1}
            scaleY={hover || selected ? 1.125 : 1}
            offsetY={heightPin / 2}
        >
            {showNameTooltip && reservable.name && (
                <>
                    <Rect
                        width={width + padding * 2}
                        height={21}
                        cornerRadius={12}
                        fill={'#333333'}
                        shadowColor="black"
                        shadowBlur={15}
                        shadowOpacity={0.2}
                        x={rectX}
                        y={rectY}
                    />
                    <Text
                        ref={textName}
                        height={40}
                        align="center"
                        x={rectX + padding}
                        y={rectY - 8}
                        verticalAlign="middle"
                        text={reservable.name || 'text'}
                        fontFamily="Poppins"
                        fontSize={12}
                        fill={'rgba(255, 255, 255, 0.9)'}
                        wrap="none"
                    />
                    <Path x={rectX + width / 2} y={rectY + 21} data={pathData} fill={'#333333'} />
                </>
            )}

            <Image id={reservable.id} image={imagePin} x={-PIN_SIZES.WIDTH_PIN / 2} y={-heightPin / 2} />

            {reservable.availabilityType === EAvailabilityType.BOOKED_FOR_TEAM && (
                <TextReservable
                    selected={selected}
                    text={getInitialsDepartment(reservable.ReservableToDepartments?.items || [])}
                    colors={Colors}
                    size={{
                        width: PIN_SIZES.WIDTH_PIN,
                        height: heightPin,
                        radius: PIN_SIZES.RADIUS_TEXT,
                        avatar: PIN_SIZES.RADIUS_TEXT,
                    }}
                    offsetX={4}
                    offsetY={heightPin / 2 - PIN_SIZES.RADIUS_TEXT}
                />
            )}

            {reservable.availabilityType === EAvailabilityType.BOOKED_FOR_PERSON && (
                <AvatarReservable
                    employee={firstEmployee}
                    size={{
                        width: PIN_SIZES.WIDTH_PIN,
                        height: PIN_SIZES.HEIGHT_SEAT_PIN,
                        radius: PIN_SIZES.WIDTH_PIN / 2 - 2,
                        avatar: PIN_SIZES.RADIUS_TEXT - 4,
                    }}
                    selected={selected}
                    colors={Colors}
                    offsetX={reservable.type === EReservableType.PARKING ? 4 : 0}
                    offsetY={offsetY}
                    activeAvatar={showAvatar}
                    text={getTextPin()}
                />
            )}

            <Circle
                id={reservable.id}
                onMouseEnter={enterHoverSeat}
                onMouseOut={outHoverSeat}
                onClick={handleSelect}
                onTouchEnd={handleSelect}
                radius={heightPin / 2}
                fill="transparent"
            />
        </Group>
    )
}

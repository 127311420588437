import * as PinImagesSvg from 'assets/pins'
import { EAvailabilityType } from 'types/data.types'

export const getSeatEditorImages = (type: EAvailabilityType, selected: boolean) => {
    switch (type) {
        case EAvailabilityType.FREE_FOR_ALL:
            return getFreeSeatImage(selected)

        case EAvailabilityType.BOOKED_FOR_TEAM:
            return getTeamSeatImage(selected)

        case EAvailabilityType.BOOKED_FOR_PERSON:
            return getPersonSeatImage(selected)

        case EAvailabilityType.UNAVALIABLE:
            return getNotAvaliableSeatImage(selected)

        default:
            return getFreeSeatImage(selected)
    }
}

export const getRoomEditorImages = (type: EAvailabilityType, selected: boolean) => {
    switch (type) {
        case EAvailabilityType.FREE_FOR_ALL:
            return getFreeRoomImage(selected)

        case EAvailabilityType.BOOKED_FOR_TEAM:
            return getTeamRoomImage(selected)

        case EAvailabilityType.UNAVALIABLE:
            return getNotAvaliableRoomImage(selected)

        default:
            return getFreeRoomImage(selected)
    }
}

export const getParkingEditorImages = (type: EAvailabilityType, selected: boolean) => {
    switch (type) {
        case EAvailabilityType.FREE_FOR_ALL:
            return getFreeParkingImage(selected)

        case EAvailabilityType.BOOKED_FOR_TEAM:
            return getTeamParkingImage(selected)

        case EAvailabilityType.BOOKED_FOR_PERSON:
            return getPersonParkingImage(selected)

        case EAvailabilityType.UNAVALIABLE:
            return getNotAvaliableParkingImage(selected)

        default:
            return getFreeParkingImage(selected)
    }
}

const getFreeSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedFreeSeat : PinImagesSvg.DefaultFreeSeat
}

const getFreeRoomImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedFreeRoom : PinImagesSvg.DefaultFreeRoom
}

const getFreeParkingImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedFreeParking : PinImagesSvg.DefaultFreeParking
}

const getTeamSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedTeamSeat : PinImagesSvg.DefaultTeamSeat
}

const getTeamRoomImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedTeamRoom : PinImagesSvg.DefaultTeamRoom
}

const getTeamParkingImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedTeamParking : PinImagesSvg.DefaultTeamParking
}

const getPersonSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedPersonSeat : PinImagesSvg.DefaultSeetEmployee
}

const getPersonParkingImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedPersonParking : PinImagesSvg.DefaultPersonParking
}

const getNotAvaliableSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedNotAvaliableSeat : PinImagesSvg.DefaultNotAvaliableSeat
}

const getNotAvaliableRoomImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedNotAvaliableRoom : PinImagesSvg.DefaultNotAvaliableRoom
}

const getNotAvaliableParkingImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedNotAvaliableParking : PinImagesSvg.DefaultNotAvaliableParking
}

import * as PinImagesSvg from 'assets/pinsV2'
import { EAvailabilityType, EReservableType } from 'types/data.types'

export const getSeatSpaceScreenImages = (
    reservableType: EReservableType,
    availabilityType: EAvailabilityType,
    selected: boolean,
    noSelectable: boolean,
    isFavorite: boolean,
    isMultiBooking: boolean,
    isBooking: boolean,
    isOnlyBlockedTime: boolean,
    towelPin?: boolean,
) => {
    if (selected) {
        return isFavorite ? PinImagesSvg.seatSelectedFavourite : PinImagesSvg.seatSelected
    }

    const pinMap = {
        [EReservableType.SEAT]: {
            [EAvailabilityType.FREE_FOR_ALL]: {
                default: PinImagesSvg.seatForAll,
                booked: PinImagesSvg.seatForAllBooked,
                multi: PinImagesSvg.seatForAllMultiBooked,
                bookedAndUnavailable: PinImagesSvg.seatForAllBookedUnavailable,
                multiAndUnavailable: PinImagesSvg.seatForAllMultiBookedUnavailable,
                unavailable: PinImagesSvg.seatForAllUnavailable,
            },
            [EAvailabilityType.BOOKED_FOR_TEAM]: {
                default: PinImagesSvg.seatForTeam,
                booked: PinImagesSvg.seatForTeamBooked,
                multi: PinImagesSvg.seatForTeamMultiBooked,
                bookedAndUnavailable: PinImagesSvg.seatForTeamBookedUnavailable,
                multiAndUnavailable: PinImagesSvg.seatForTeamMultiBookedUnavailable,
                unavailable: PinImagesSvg.seatForTeamUnavailable,
            },
            [EAvailabilityType.BOOKED_FOR_PERSON]: {
                default: PinImagesSvg.seatForPerson,
                booked: PinImagesSvg.seatForPersonBooked,
                multi: PinImagesSvg.seatForPersonMultiBooked,
                bookedAndUnavailable: PinImagesSvg.seatForPersonBookedUnavailable,
                multiAndUnavailable: PinImagesSvg.seatForPersonMultiBookedUnavailable,
                unavailable: PinImagesSvg.seatForPersonUnavailable,
            },
            [EAvailabilityType.UNAVALIABLE]: {
                default: PinImagesSvg.pinUnavailable,
                booked: PinImagesSvg.pinUnavailable,
                multi: PinImagesSvg.pinUnavailable,
                bookedAndUnavailable: PinImagesSvg.pinUnavailable,
                multiAndUnavailable: PinImagesSvg.pinUnavailable,
                unavailable: PinImagesSvg.pinUnavailable,
            },
        },
        [EReservableType.PARKING]: {
            [EAvailabilityType.FREE_FOR_ALL]: {
                default: PinImagesSvg.parkingForAll,
                booked: PinImagesSvg.parkingForAllBooked,
                multi: PinImagesSvg.parkingForAllMultiBooked,
                bookedAndUnavailable: PinImagesSvg.parkingForAllBookedUnavailable,
                multiAndUnavailable: PinImagesSvg.parkingForAllMultiBookedUnavailable,
                unavailable: PinImagesSvg.parkingForAllUnavailable,
            },
            [EAvailabilityType.BOOKED_FOR_TEAM]: {
                default: PinImagesSvg.parkingForTeam,
                booked: PinImagesSvg.parkingForTeamBooked,
                multi: PinImagesSvg.parkingForTeamMultiBooked,
                bookedAndUnavailable: PinImagesSvg.parkingForTeamBookedUnavailable,
                multiAndUnavailable: PinImagesSvg.parkingForTeamMultiBookedUnavailable,
                unavailable: PinImagesSvg.parkingForTeamUnavailable,
            },
            [EAvailabilityType.BOOKED_FOR_PERSON]: {
                default: PinImagesSvg.parkingForPerson,
                booked: PinImagesSvg.parkingForPersonBooked,
                multi: PinImagesSvg.parkingForPersonMultiBooked,
                bookedAndUnavailable: PinImagesSvg.parkingForPersonBookedUnavailable,
                multiAndUnavailable: PinImagesSvg.parkingForPersonMultiBookedUnavailable,
                unavailable: PinImagesSvg.parkingForPersonUnavailable,
            },
            [EAvailabilityType.UNAVALIABLE]: {
                default: PinImagesSvg.pinUnavailable,
                booked: PinImagesSvg.pinUnavailable,
                multi: PinImagesSvg.pinUnavailable,
                bookedAndUnavailable: PinImagesSvg.pinUnavailable,
                multiAndUnavailable: PinImagesSvg.pinUnavailable,
                unavailable: PinImagesSvg.pinUnavailable,
            },
        },
    }

    const pinType = pinMap[reservableType][availabilityType]

    if (noSelectable) {
        if (isMultiBooking || isOnlyBlockedTime) {
            return pinType.multiAndUnavailable
        }
        if (isBooking) {
            if (towelPin) {
                return PinImagesSvg.towelPin
            }
            return pinType.bookedAndUnavailable
        }
        return pinType.unavailable
    }

    if (isMultiBooking || isOnlyBlockedTime) {
        return pinType.multi
    }

    if (isBooking) {
        if (towelPin) {
            return PinImagesSvg.towelPin
        }
        return pinType.booked
    }

    return pinType.default
}

export const getRoomSpaceScreenImages = (noSelectable: boolean, selected: boolean) => {
    if (noSelectable) {
        return PinImagesSvg.roomUnavailable
    }
    if (selected) {
        return PinImagesSvg.roomSelected
    }
    return PinImagesSvg.roomFree
}

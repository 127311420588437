import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Reservable, type CalendarEvents } from 'graphql/autogenerate/schemas'
import { getAWSFormatDate } from 'helpers'

type MeetingRoomsState = {
    officeId: string
    date: string
    selectedEvent: CalendarEvents | null
    events: CalendarEvents[]
    rooms: Reservable[]
}

export const initialState: MeetingRoomsState = {
    officeId: '',
    selectedEvent: null,
    date: getAWSFormatDate(new Date()),
    events: [],
    rooms: [],
}

const meetingRoomsSlice = createSlice({
    name: 'meetingRooms',
    initialState,
    reducers: {
        setOfficeId(state, action: PayloadAction<string>) {
            state.officeId = action.payload
        },
        setDate(state, action: PayloadAction<string>) {
            state.date = action.payload
        },
        setRooms(state, action: PayloadAction<Reservable[]>) {
            state.rooms = action.payload
        },
        setEvents(state, action: PayloadAction<CalendarEvents[]>) {
            state.events = action.payload
        },
        selectEvent(state, action: PayloadAction<CalendarEvents | null>) {
            state.events = state.events.filter((event) => event.id !== 'temporary')
            state.selectedEvent = action.payload
        },
        resetSelectedEvent(state) {
            state.events = state.events.filter((event) => event.id !== 'temporary')
            state.selectedEvent = null
        },
        addNewEvent(state, action: PayloadAction<CalendarEvents>) {
            // remove the event if it already exists
            state.events = state.events.filter((event) => event.id !== action.payload.id && event.id !== 'temporary')
            state.events.push(action.payload)
            state.selectedEvent = action.payload
        },
        changeEvent(state, action: PayloadAction<Partial<CalendarEvents>>) {
            state.events = state.events.map((event) =>
                event.id === action.payload.id
                    ? {
                          ...event,
                          ...action.payload,
                      }
                    : event,
            )
            if (state.selectedEvent && state.selectedEvent?.id === action.payload.id) {
                state.selectedEvent = {
                    ...state.selectedEvent,
                    ...action.payload,
                    id: state.selectedEvent.id,
                }
            }
        },
    },
})

export const meetingRoomsActions = meetingRoomsSlice.actions
export default meetingRoomsSlice.reducer

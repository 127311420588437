import React, { FC } from 'react'

import { TextFieldProps, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { CustomInputRoot, RequiredLabel } from './styles'

type Props = {
    minHeight?: number
    maxWidth?: number
    dynamicWidth?: boolean
    showCustomRequired?: boolean
    fieldType?: 'input' | 'textarea'
    helperText?: string
    background?: string
    externalLabel?: string | null
} & TextFieldProps

const inputProps = {
    disableUnderline: true,
}

const CustomInput: FC<Props> = ({
    error,
    minHeight,
    maxWidth,
    fieldType = 'input',
    dynamicWidth = false,
    helperText,
    background,
    variant = 'standard',
    InputProps,
    showCustomRequired,
    ...props
}) => {
    const { t } = useTranslation('translation')

    return (
        <CustomInputRoot
            className="custom_input_root"
            maxWidth={maxWidth}
            minHeight={minHeight ? minHeight : 56}
            dynamicWidth={dynamicWidth}
            hasError={error}
            type={fieldType}
            background={background}
        >
            <TextField
                error={error}
                variant={variant as any}
                InputProps={{ ...inputProps, ...InputProps }}
                helperText={error ? helperText : undefined}
                {...props}
                required={showCustomRequired ? false : props.required}
                label={
                    <span>
                        {props.label}
                        {props.required && showCustomRequired && !error && !props.value && (
                            <RequiredLabel className={'required-label'}>{t('Required')}</RequiredLabel>
                        )}
                    </span>
                }
            />
        </CustomInputRoot>
    )
}

export default CustomInput

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
    AggregatedRoomData,
    CompanyUtilizationChartDataCompany,
    OfficeUtilizationByWeekDaysChartData,
    SpaceBooksChartData,
} from '../../../types/chartsData.types'

type DashboardState = {
    // Add the state properties here
    officeUtilizationChartData: CompanyUtilizationChartDataCompany[] | null
    officeUtilizationByWeekdaysChartData: OfficeUtilizationByWeekDaysChartData[] | null
    spaceUtilizationChartData: SpaceBooksChartData[] | null
    roomUtilizationChartData: AggregatedRoomData[] | null
}

export const initialState: DashboardState = {
    // Initialize the state properties here
    officeUtilizationChartData: null,
    officeUtilizationByWeekdaysChartData: null,
    spaceUtilizationChartData: null,
    roomUtilizationChartData: null,
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        // Add the reducers here
        setOfficeUtilizationChartData: (state, action: PayloadAction<CompanyUtilizationChartDataCompany[] | null>) => {
            state.officeUtilizationChartData = action.payload
        },
        setOfficeUtilizationByWeekdaysChartData: (
            state,
            action: PayloadAction<OfficeUtilizationByWeekDaysChartData[] | null>,
        ) => {
            state.officeUtilizationByWeekdaysChartData = action.payload
        },
        setSpaceUtilizationChartData: (state, action: PayloadAction<SpaceBooksChartData[] | null>) => {
            state.spaceUtilizationChartData = action.payload
        },
        setRoomUtilizationChartData: (state, action: PayloadAction<AggregatedRoomData[] | null>) => {
            state.roomUtilizationChartData = action.payload
        },
    },
})

export const dashboardActions = dashboardSlice.actions
export default dashboardSlice.reducer

import React, { FC, useRef, useState } from 'react'

import Konva from 'konva'
import { Circle, Group, Image } from 'react-konva'

import { useResizeDevice } from 'context'
import { useAppSelector } from 'hooks'
import { AbsoluteCoords, EReservableType } from 'types/data.types'

import AvatarReservable from './AvatarReservable'
import FavoriteSeat from './FavoriteSeat'
import TextBookingsAmount from './TextBookingsAmount'
import { RESERVABLE_COLORS } from '../helpers/constants'
import { getColorCircleBlocked } from '../helpers/getColors'
import { getSpaceScreenSeatSize } from '../helpers/getSizes'
import { useSpaceScreenPinsImages } from '../hooks/useSpaceScreenPinsImages'
import { HoverSpaceScreenSeat, ReservableSpaceScreen, SpaceScreenMapPinType, HoverSpaceScreenSeatStar } from '../types'

type Props = {
    seat: ReservableSpaceScreen
    selected: boolean
    onSelect: (val: ReservableSpaceScreen) => void
    onHover: (hover: HoverSpaceScreenSeat | null) => void
    onHoverStar?: (hover: HoverSpaceScreenSeatStar | null) => void
    selectable?: boolean
    zoom?: number
}

export const SeatSpaceScreenReservable: FC<Props> = ({
    seat,
    selected,
    onSelect,
    onHover,
    selectable = true,
    onHoverStar,
}) => {
    const groupRef = useRef<Konva.Group | null>(null)
    const { isMobile } = useResizeDevice()

    const [hover, setHover] = useState(false)
    const [hoverStar, setHoverStar] = useState(false)
    const favouriteReservableID = useAppSelector(({ user }) => user.favouriteReservableID)
    const {
        id,
        typeSeat,
        x,
        y,
        bookings,
        selectable: selectableReservable,
        typeReservable,
        availabilityType,
        isMultiBooking,
        isBooking,
    } = seat

    const { pinType, isOnlyBlockedTime } = typeSeat
    const noSelectable = !selectable || !selectableReservable
    const employee = bookings.length > 0 ? bookings[0].Employee : null
    const amountBookingsString = bookings.length.toString()

    const { imagePin, hasAvatar } = useSpaceScreenPinsImages(
        typeReservable,
        typeSeat,
        availabilityType,
        selected,
        noSelectable,
        favouriteReservableID === seat.id,
        isMultiBooking,
        isBooking,
    )

    const size = getSpaceScreenSeatSize(pinType)
    const offsetY = typeReservable === EReservableType.PARKING ? size.radius - 8 : 3

    const enterHoverSeat = (e: Konva.KonvaEventObject<MouseEvent>) => {
        const stage = e.currentTarget.getStage()
        if (stage === null) {
            return
        }

        setHover(true)

        groupRef.current && groupRef.current.moveToTop()

        if (noSelectable) {
            stage.container().style.cursor = 'not-allowed'
        } else {
            stage.container().style.cursor = 'pointer'
        }

        const coords: AbsoluteCoords = {
            x: e.currentTarget.getAbsolutePosition().x,
            y: e.currentTarget.getAbsolutePosition().y,
            offset: (size.radius + offsetY + 14) * stage.scaleX(),
        }

        onHover({ seat, coords })
        setHoverStar(true)
    }

    const outHoverSeat = (e: Konva.KonvaEventObject<MouseEvent>) => {
        setHover(false)
        const stage = e.currentTarget.getStage()

        if (stage) {
            stage.container().style.cursor = 'auto'
        }
        onHover(null)
    }

    const handleSelect = () => {
        if (noSelectable) {
            return
        }
        onSelect(seat)
    }

    if (isMultiBooking || isOnlyBlockedTime) {
        size.radius = 20
        size.avatar = 16
    }

    // const colorText = getColorTextAmount(pinType, isOnlyBlockedTime)

    const Colors = {
        colorBack: RESERVABLE_COLORS.TEXT,
        colorSelectedBack: RESERVABLE_COLORS.SELECTED,
        colorText: RESERVABLE_COLORS.TEXT_WHITE,
        colorSelectedText: RESERVABLE_COLORS.TEXT_WHITE,
    }

    if (noSelectable) {
        Colors.colorBack = RESERVABLE_COLORS.TEXT_WHITE
        Colors.colorText = RESERVABLE_COLORS.TEXT
    }

    const scale = hover || selected ? 1.125 : 1

    return (
        <Group ref={groupRef} id={id} x={x} y={y} scaleX={scale} scaleY={scale} offsetY={26} move>
            {isOnlyBlockedTime && (
                <Circle radius={size.avatar} fill={getColorCircleBlocked(pinType, selected, isOnlyBlockedTime)} />
            )}

            {(isMultiBooking || isOnlyBlockedTime) && (
                <TextBookingsAmount
                    colors={{
                        colorText: Colors.colorText,
                        colorSelectedText: Colors.colorSelectedBack,
                        colorBack: Colors.colorBack,
                        colorSelectedBack: Colors.colorSelectedText,
                    }}
                    amount={amountBookingsString}
                    selected={selected}
                    size={size}
                    offsetX={4}
                    offsetY={offsetY - 2}
                />
            )}

            {employee && hasAvatar && !isOnlyBlockedTime && !isMultiBooking && (
                <AvatarReservable
                    colors={Colors}
                    selected={selected}
                    employee={employee}
                    size={size}
                    offsetY={offsetY}
                />
            )}

            <Image
                id={id}
                image={imagePin}
                x={imagePin?.width && -imagePin.width / 2}
                y={imagePin?.height && -imagePin.height / 2}
                shadowColor="black"
                offsetY={0}
            />

            <Circle
                radius={size.radius + 5}
                fill="transparent"
                id={id}
                onMouseEnter={enterHoverSeat}
                onMouseOut={outHoverSeat}
                onClick={handleSelect}
                onTouchEnd={handleSelect}
            />
            {![
                SpaceScreenMapPinType.UNAVAILABLE_FREE,
                SpaceScreenMapPinType.UNAVAILABLE_MULTI_BOOKED,
                SpaceScreenMapPinType.BLOCKED,
            ].includes(pinType) &&
                (hoverStar || favouriteReservableID === seat.id) &&
                !isMobile &&
                !selected &&
                seat.typeReservable !== 'PARKING' && (
                    <Group
                        scaleX={scale}
                        scaleY={scale}
                        visible={hover || favouriteReservableID === seat.id}
                        onMouseEnter={enterHoverSeat}
                        onMouseOut={outHoverSeat}
                    >
                        <FavoriteSeat
                            onHoverStar={onHoverStar}
                            size={size}
                            offsetY={offsetY - 7}
                            offsetX={20}
                            reservableID={seat.id}
                        />
                    </Group>
                )}
        </Group>
    )
}

import { FC } from 'react'

import { Circle, Text } from 'react-konva'

import { ColorAvatarSeat } from '../types'

type Props = {
    colors: ColorAvatarSeat
    text: string
    selected: boolean
    size: {
        width: number
        height: number
        radius: number
        avatar: number
    }
    offsetY?: number
    offsetX?: number
}

const TextReservable: FC<Props> = ({
    text,
    selected,
    size: { width, radius, avatar },
    colors: { colorBack, colorSelectedText, colorText },
    offsetY,
    offsetX,
}) => {
    const colorBackground = colorBack
    const colorFillText = selected ? colorSelectedText : colorText

    return (
        <>
            <Circle radius={avatar} fill={colorBackground} />
            <Text
                width={width}
                x={-width / 2}
                y={-radius / 2 + 2}
                align="center"
                text={text}
                fontFamily="Poppins"
                fontSize={12}
                fontStyle="bold"
                fill={colorFillText}
                offsetY={offsetY}
                offsetX={offsetX}
            />
        </>
    )
}

export default TextReservable

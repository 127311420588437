import { ReservableSpaceScreen } from 'components/organisms/Reservable'
import { SpaceScreenSeatPin } from 'components/organisms/Reservable/types'
import { BookingCheckInStatus } from 'components/organisms/SeatPicker/types'
import { SpaceListItem } from 'components/organisms/SpaceListDropDown/types'
import { TTag } from 'components/shared/types'
import { RepeatType } from 'graphql/autogenerate/schemas'
import { BookingSubType, BookingType } from 'types/common.types'
import {
    EmployeeStatus,
    EReservableType,
    ERoleEmployee,
    MainEmployeeData,
    TOffice,
    TPosition,
    CalendarEmployee,
    EAvailabilityType,
    PIN_CIRCLE,
} from 'types/data.types'

import * as T from '../../types/data.types'

export { RepeatType as MobileScheduleRepeatType } from 'graphql/autogenerate/schemas'

export type Employee = {
    id: string
    name: string
    Office: {
        id: string
        name: string
    }
}

export type MobileScheduleBookingReservable = ScheduleBookingReservableRoom & {
    id: string
    name: string
    type: EReservableType
    x: number
    y: number
}

export type MobileScheduleBookingRequest = {
    id: string
    addParkingSlot: boolean
    repeatType: RepeatType | null
    daysOfWeek: Array<string> | null
    parkingSlotID: string | null
    ParkingSlot?: {
        name: string
        availabilityType: EAvailabilityType
        id: string
        x: number
        y: number
        bookings: Array<T.PickerReservableBooking>
        typeSeat: SpaceScreenSeatPin
        typeReservable: EReservableType
        nameRoom: string
        preview: Array<PIN_CIRCLE> | null
        startSpaceWorkingTime: string
        endSpaceWorkingTime: string
        selectable: boolean
        tags: Array<TTag>
        zonePoints?: number[]
        color?: string
        Space?: {
            id: string
            name: string
        }
    }
    Reservable?: ReservableSpaceScreen | null
}

export type SpaceInfo = {
    id: string
    name: string
    workingHoursFrom: string
    workingHoursTo: string
    floorPlanFilePath: string | null
    Office: {
        id: string
        name: string
        timeZone: string
    }
}

export type CalendarScheduleBooking = {
    id: string
    startTime: string
    endTime: string
    reservableID: string
    isBlocked: boolean
    Space: SpaceInfo | null
    bookingType: BookingType | null
    bookingSubType: BookingSubType | null
    isTeamEvent: boolean
    isEvent?: boolean
    reservable: MobileScheduleBookingReservable | null
    BookingRequest: MobileScheduleBookingRequest | null
    TeamEventRequest?: MobileScheduleBookingRequest | null
    checkInStatus: BookingCheckInStatus
    Employees?: {
        items?: Array<CalendarEmployee>
    }
    events?: CalendarScheduleBooking[]
}

export type ScheduleBookingReservableRoom = {
    Space?: SpaceListItem | null
}

export type CalendarScheduleBookingRoom = CalendarScheduleBooking & {
    Reservable: MobileScheduleBookingReservable
    onlineMeetingUrl: string
    description: string
    title: string
}

export type MobileScheduleEmployee = {
    id: string
    companyID: string
    departmentIDs?: string[]
    departmentsString: string
    positionID: string | null
    firstname: string
    lastname: string
    fullName: string
    photo: string | null
    email: string
    Position: TPosition | null
    Office: TOffice | null
    role: ERoleEmployee
    status: EmployeeStatus
    BookingsByStartTime: {
        items: Array<CalendarScheduleBooking>
    }
}

export const MobileScheduleColors: { [key: string]: string } = {
    [BookingType.REMOTE]: '#6FD8E7',
    [BookingType.OFFICE]: '#5CE092',
    [BookingType.TEAM_EVENT]: '#5CE092',
    [BookingType.NOT_AVAILABLE]: '#FB9394',
    [BookingSubType.VACATION]: '#FB9394',
    [BookingSubType.SICK_LEAVE]: '#FB9394',
    [BookingSubType.BUSINESS_TRIP]: '#FB9394',
}

export enum MobileScheduleHomeSwitchType {
    ME = 'ME',
    TEAM = 'TEAM',
}

export const BookingRepeatList: Array<{ id: RepeatType; label: string }> = [
    { id: RepeatType.NEVER, label: 'Never' },
    { id: RepeatType.WEEKLY, label: 'Every week' },
    { id: RepeatType.CUSTOM, label: 'Custom' },
]

export type CalendarEmployeeData = {
    BookingsByWeek: {
        items: Array<CalendarScheduleBooking>
    }
    CalendarEventsByWeek: {
        items: Array<{ CalendarEvent: CalendarScheduleBookingRoom }>
    }
} & MainEmployeeData

export type StatusesFilter = {
    eq?: EmployeeStatus
    ne?: EmployeeStatus
}

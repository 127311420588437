import { EventInput } from '@fullcalendar/core'
import { createSlice, PayloadAction, ActionReducerMapBuilder, Draft } from '@reduxjs/toolkit'
import moment from 'moment'

import { ReservableSpaceScreen } from 'components/organisms/Reservable'
import {
    Booking,
    Reservable,
    Space,
    RepeatType as MobileScheduleRepeatType,
    type CalendarEvents,
} from 'graphql/autogenerate/schemas'
import { getAWSFormatDate, getConvertedEngLocaleDate } from 'helpers'
import { MainEmployeeData, EReservableType } from 'types/data.types'

import { fetchCurrentBookingById, fetchReservableById, fetchReservableRoomById } from './editBookingThunks'
import { initialStateProps } from '../types'

import type { FormValues, MeetingFormValues } from 'components/shared/widgets/CalendarInfoDialog/types'

type SelectWeekNumberType = {
    weekNumber: number
    year: number
}

const initialState: initialStateProps = {
    date: getAWSFormatDate(moment()),
    year: moment().year(),
    weekNumber: moment().isoWeek(),
    scheduleBookings: [],
    scheduleMeetings: [],
    events: [],
    placeBookings: [],
    reservables: [],
    open: false,
    startWorkTime: '08:00',
    endWorkTime: '24:00',
    employeeID: '',
    currentReservable: null,
    suggestedSeat: null,
    loadingTimeLine: false,
    loadingBookingInfo: false,
    suggestedSpace: null,
    currentSpace: null,
    currentSpaceForParking: null,
    bookingRepeatType: MobileScheduleRepeatType.NEVER,
    repeatDaysOfWeek: [getConvertedEngLocaleDate(moment(getAWSFormatDate(moment())), 'dddd')],
    selectedTeamEventMembers: [],
    selectedEmployee: null,
    checkInStatus: false,
    currentBooking: null,
    editFormValue: null,
    editMeetingFormValue: null,
    parkingMapStatus: false,
    currentParkingReservable: null,
    suggestedSeatID: null,
    repeatDate: [],
    isSpaceMapLoading: false,
    spaceLoader: false,
    selectedBookingTime: ['00:00', '24:00'],
    availableTimeForBooking: ['00:00', '24:00'],
    isSeatAvailable: true,
    editBookingPopup: false,
    isEditMode: false,
    isParking: false,
    isNotAvailableSeatReason: '',
    /*TEAMEVENTTIMELINE*/
    reservableID: null,
    currentRoomEvent: null,
    openMapState: true,
    isMeetingPlace: false,
    loadingRoomTimeLine: false,
    roomBookings: [],
    roomEvents: [],
}

const editBookingSlice = createSlice({
    name: 'editBooking',
    initialState,
    reducers: {
        init: (state, action: PayloadAction<Partial<initialStateProps>>) => {
            return {
                ...state,
                ...action.payload,
            }
        },

        selectCurrentReservable: (state, action: PayloadAction<ReservableSpaceScreen | null>) => {
            return {
                ...state,
                currentReservable: action.payload,
                isMeetingPlace: action.payload?.typeReservable === EReservableType.ROOM,
            }
        },

        selectSuggestedSeat: (state, action: PayloadAction<ReservableSpaceScreen | null>) => {
            return {
                ...state,
                suggestedSeat: action.payload,
            }
        },

        setDate: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                date: action.payload,
            }
        },

        selectDate: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                date: action.payload,
                currentBooking:
                    (state.currentBooking?.startTime || '').substring(0, 10) !== action.payload
                        ? null
                        : state.currentBooking,
            }
        },

        selectWeekNumber: (state, action: PayloadAction<SelectWeekNumberType>) => {
            return {
                ...state,
                weekNumber: action.payload.weekNumber,
                year: action.payload.year,
            }
        },

        setExistingBookings: (state, action: PayloadAction<Array<Booking>>) => {
            return {
                ...state,
                scheduleBookings: action.payload,
                open: true,
                checkInStatus: true,
            }
        },

        setSpace: (state, action: PayloadAction<Space>) => {
            return {
                ...state,
                startWorkTime: action.payload.workingHoursFrom,
                endWorkTime: action.payload.workingHoursTo,
                currentSpace: action.payload,
                currentReservable:
                    state.currentSpace?.officeID !== action.payload.officeID ? null : state.currentReservable, // reset previous selected reservable because it's related to the previous Office
            }
        },

        setReservables: (state, action: PayloadAction<Array<ReservableSpaceScreen>>) => {
            return {
                ...state,
                reservables: action.payload,
            }
        },

        switchEditBooking: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                open: action.payload,
            }
        },

        setLoadingTimeLine: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                loadingTimeLine: action.payload,
            }
        },

        setSpaceLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                spaceLoader: action.payload,
            }
        },

        setLoadingBookingInfo: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                loadingBookingInfo: action.payload,
            }
        },

        setSpaceMapLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSpaceMapLoading: action.payload,
            }
        },

        setCheckInStatus: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                checkInStatus: action.payload,
            }
        },

        setBookingRepeatType: (state, action: PayloadAction<MobileScheduleRepeatType>) => {
            return {
                ...state,
                bookingRepeatType: action.payload,
                repeatDaysOfWeek: [getConvertedEngLocaleDate(moment(state.date), 'dddd')],
            }
        },

        setParking: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isParking: action.payload,
            }
        },

        setRepeatDaysOfWeek: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                repeatDaysOfWeek: action.payload,
            }
        },

        setTeamEventMembers: (state, action: PayloadAction<Array<MainEmployeeData>>) => {
            return {
                ...state,
                selectedTeamEventMembers: action.payload,
            }
        },

        setEvents(state, action: PayloadAction<EventInput[]>) {
            state.events = action.payload
        },

        addEvent(state, action: PayloadAction<EventInput>) {
            state.events.push(action.payload)
        },

        updateEvent(state, action: PayloadAction<{ id: string; event: Partial<EventInput> }>): void {
            const index = state.events.findIndex((e) => e.id === action.payload.id)
            if (index !== -1) {
                state.events[index] = { ...state.events[index], ...action.payload.event }
            }
        },

        removeEvent(state, action: PayloadAction<string>) {
            state.events = state.events.filter((e) => e.id !== action.payload)
        },

        setCurrentBooking: (state, action: PayloadAction<Booking | null>) => {
            const payload = action.payload
            if (!payload) {
                return {
                    ...state,
                    currentBooking: null,
                    selectedBookingTime: ['00:00', '24:00'],
                    currentReservable: null,
                }
            }

            const timeStart = moment.utc(payload?.startTime || state.startWorkTime).format('HH:mm')
            const timeEnd = moment.utc(payload?.endTime || state.endWorkTime).format('HH:mm')
            const reservable = payload?.reservable

            const newState: initialStateProps = {
                ...state,
                currentBooking: payload,
                selectedBookingTime: [timeStart, timeEnd],
            }
            if ((payload.bookingType as string) === 'APPROVED') {
                newState.currentReservable = state.currentReservable
                return
            }

            if (reservable && payload?.bookingType === 'OFFICE') {
                const convertedReservable = {
                    name: reservable.name,
                    availabilityType: reservable.availabilityType,
                    id: reservable.id,
                    x: reservable.x,
                    y: reservable.y,
                    typeReservable: reservable.type,
                    nameRoom: reservable.name,
                    zonePoints: reservable?.zonePoints,
                    color: reservable?.color,
                    space: (reservable?.Space as Space) || payload?.Space || null,
                    availabilityByDatesAndTime: reservable.availabilityByDatesAndTime,
                }
                newState.currentReservable = {
                    ...convertedReservable,
                } as unknown as ReservableSpaceScreen
            } else if (payload?.bookingType !== 'OFFICE') {
                newState.currentReservable = null
                newState.isSpaceMapLoading = false
            }

            if (payload?.BookingRequest?.repeatType) {
                newState.bookingRepeatType = payload.BookingRequest?.repeatType
                newState.repeatDaysOfWeek = [getConvertedEngLocaleDate(moment(state.date), 'dddd')]

                if (payload?.BookingRequest?.daysOfWeek) {
                    newState.repeatDaysOfWeek = payload?.BookingRequest?.daysOfWeek
                }

                if (payload?.BookingRequest?.dates) {
                    newState.repeatDate = payload?.BookingRequest?.dates
                }
            } else {
                newState.bookingRepeatType = MobileScheduleRepeatType.NEVER
                if (payload?.BookingRequest?.daysOfWeek) {
                    newState.repeatDaysOfWeek = []
                }
                if (payload?.BookingRequest?.dates) {
                    newState.repeatDate = []
                }
            }

            if (payload.BookingRequest?.addParkingSlot) {
                newState.isParking = true
                newState.currentParkingReservable = {
                    id: payload.BookingRequest.ParkingSlot?.id,
                    name: payload.BookingRequest.ParkingSlot?.name,
                    space: payload.BookingRequest.ParkingSlot?.Space || null,
                    typeReservable: 'PARKING',
                } as unknown as ReservableSpaceScreen
            }

            return newState
        },

        changeCurrentBooking: (state, action: PayloadAction<Booking>) => {
            const payload = action.payload
            if (!payload) {
                return {
                    ...state,
                    currentBooking: null,
                    selectedBookingTime: ['00:00', '24:00'],
                    currentReservable: null,
                }
            }

            const timeStart = moment.utc(payload?.startTime || state.startWorkTime).format('HH:mm')
            const timeEnd = moment.utc(payload?.endTime || state.endWorkTime).format('HH:mm')

            return {
                ...state,
                currentBooking: action.payload,
                selectedBookingTime: [timeStart, timeEnd],
            }
        },

        setFormEdit: (state, action: PayloadAction<FormValues>) => {
            return {
                ...state,
                editFormValue: action.payload,
            }
        },

        setMeetingFormEdit: (state, action: PayloadAction<MeetingFormValues>) => {
            return {
                ...state,
                editMeetingFormValue: {
                    ...state.editMeetingFormValue,
                    ...action.payload,
                },
            }
        },

        setCurrentRoomEvent: (state, action: PayloadAction<Partial<CalendarEvents>>) => {
            console.log('setCurrentRoomEvent', action.payload, state.currentRoomEvent)
            // @ts-ignore
            state.currentRoomEvent = {
                ...state.currentRoomEvent,
                ...action.payload,
            }
        },

        setEdit: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isEditMode: action.payload,
            }
        },

        selectRepeatDate: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                repeatDate: action.payload,
            }
        },

        setSelectedEmployee: (state, action: PayloadAction<MainEmployeeData | null>) => {
            return {
                ...state,
                selectedEmployee: action.payload,
            }
        },

        setScheduleForReservable: (state, action: PayloadAction<Array<Booking>>) => {
            return {
                ...state,
                placeBookings: action.payload,
            }
        },

        setIsSeatAvailable: (
            state,
            action: PayloadAction<{ isSeatAvailable: boolean; isNotAvailableSeatReason: string }>,
        ) => ({
            ...state,
            isSeatAvailable: action.payload.isSeatAvailable,
            isNotAvailableSeatReason: action.payload.isNotAvailableSeatReason,
        }),

        /*Parking*/
        selectCurrentReservableParking: (state, action: PayloadAction<ReservableSpaceScreen | null>) => {
            return {
                ...state,
                currentParkingReservable: action.payload,
            }
        },

        setParkingMap: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                parkingMapStatus: action.payload,
            }
        },

        afterSaveEvent: (state) => {
            return {
                ...state,
                loadingBookingInfo: false,
                // currentSpace: null,
                // selectedEmployee: null,
                currentBooking: null,
                // selectedBookingTime: ['00:00', '24:00'],
                checkInStatus: false,
                loadingTimeLine: true,
            }
        },

        /*TEAMEVENTTIMELINE*/

        setReservableId: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                reservableID: action.payload,
                openMapState: false,
            }
        },

        setMapState: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                openMapState: action.payload,
            }
        },

        setRoomScheduleForReservable: (state, action: PayloadAction<Array<Booking>>) => {
            return {
                ...state,
                roomBookings: action.payload,
            }
        },

        setRoomEvents(state, action: PayloadAction<EventInput[]>) {
            state.roomEvents = action.payload
        },

        addRoomEvent(state, action: PayloadAction<EventInput>) {
            state.roomEvents.push(action.payload)
        },

        updateRoomEvent(state, action: PayloadAction<{ id: string; event: Partial<EventInput> }>): void {
            const index = state.roomEvents.findIndex((e) => e.id === action.payload.id)
            if (index !== -1) {
                state.roomEvents[index] = { ...state.roomEvents[index], ...action.payload.event }
            }
        },

        removeRoomEvent(state, action: PayloadAction<string>) {
            state.roomEvents = state.roomEvents.filter((e) => e.id !== action.payload)
        },

        reset: (state) => ({
            ...initialState,
            // don't reset working time
            employeeID: state.employeeID,
            startWorkTime: state.startWorkTime,
            endWorkTime: state.endWorkTime,
            date: state.date,
        }),
    },
    extraReducers: (builder: ActionReducerMapBuilder<initialStateProps>) => {
        builder
            .addCase(fetchCurrentBookingById.pending, (state: Draft<initialStateProps>) => {
                return {
                    ...state,
                    loadingTimeLine: true,
                }
            })
            .addCase(
                fetchCurrentBookingById.fulfilled,
                (state: Draft<initialStateProps>, action: PayloadAction<Booking>) => {
                    const formattedDate = moment(action.payload.startTime).format('YYYY-MM-DD') // Формат даты: ГГГГ-ММ-ДД
                    return {
                        ...state,
                        currentBooking: action.payload,
                        date: formattedDate,
                    }
                },
            )
            .addCase(fetchCurrentBookingById.rejected, (state: Draft<initialStateProps>, action) => {
                console.error('Failed to fetch reservable:', action.error.message)
                return {
                    ...state,
                }
            })

        builder
            .addCase(fetchReservableById.pending, (state: Draft<initialStateProps>) => {
                return {
                    ...state,
                    loadingTimeLine: true,
                }
            })
            .addCase(
                fetchReservableById.fulfilled,
                (state: Draft<initialStateProps>, action: PayloadAction<Reservable>) => {
                    const bookings = action.payload?.BookingsByDateAndDayOfWeek?.items

                    const filteredBookings = bookings?.filter(
                        (booking) => booking?.Employee?.id !== state?.employeeID,
                    ) as Booking[]
                    return {
                        ...state,
                        placeBookings: filteredBookings,
                        loadingTimeLine: false,
                    }
                },
            )
            .addCase(fetchReservableById.rejected, (state: Draft<initialStateProps>, action) => {
                console.error('Failed to fetch reservable:', action.error.message)
                return {
                    ...state,
                    loadingTimeLine: false,
                }
            })
        builder
            .addCase(fetchReservableRoomById.pending, (state: Draft<initialStateProps>) => {
                return {
                    ...state,
                    loadingRoomTimeLine: true,
                    loadingTimeLine: false,
                }
            })
            .addCase(
                fetchReservableRoomById.fulfilled,
                (state: Draft<initialStateProps>, action: PayloadAction<CalendarEvents[]>) => {
                    const bookings = action.payload as unknown as Booking[]
                    return {
                        ...state,
                        roomBookings: bookings,
                        loadingRoomTimeLine: false,
                        loadingTimeLine: false,
                    }
                },
            )
            .addCase(fetchReservableRoomById.rejected, (state: Draft<initialStateProps>, action) => {
                console.error('Failed to fetch reservable:', action.error.message)
                return {
                    ...state,
                    loadingRoomTimeLine: false,
                    loadingTimeLine: false,
                }
            })
    },
})

export const editBookingActions = editBookingSlice.actions
export default editBookingSlice.reducer

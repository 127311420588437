// src/context/AuthProvider.tsx

import React, { createContext, FC, useContext, useEffect, useState, useCallback, useMemo } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

// Helper Functions and Constants
import { getUserSessionGroup, noop, noopAsync, getErrorMessage } from 'helpers'
import analytics, { AnalyticsEvents } from 'helpers/analytics'
import { getNewToken } from 'helpers/getNewToken'
import { JwtTokenName } from 'shared/constants'
import { AppDispatch } from 'store'
import { messageActions } from 'store/slices/message'
import { userActions } from 'store/slices/user'
import { ROUTES } from 'types/routes'
import { EUserGroup, TCustomUserAttributes, TSignUpData, TUser, TUserAttributes } from 'types/user.types'

import { TAuthContext } from './auth.types'
import { usePreloader } from '../preloader'

// ==============================
// Helper Functions
// ==============================

/**
 * Retrieves the current authenticated user.
 */
const fetchCurrentUser = async (): Promise<CognitoUser | void> => {
    try {
        return await Auth.currentAuthenticatedUser()
    } catch (err) {
        console.error('Not signed in', err)
    }
}

/**
 * Determines the highest priority user group.
 */
const determineUserGroup = (groups?: EUserGroup[] | null): EUserGroup => {
    if (groups?.includes(EUserGroup.MANAGER)) {
        return EUserGroup.MANAGER
    }
    if (groups?.includes(EUserGroup.TEAM_LEAD)) {
        return EUserGroup.TEAM_LEAD
    }
    return EUserGroup.EMPLOYEE
}

// ==============================
// Context Setup
// ==============================

const AuthContext = createContext<TAuthContext>({
    userAuth: null,
    isAuth: false,
    userGroup: EUserGroup.EMPLOYEE,
    signIn: noop,
    signOut: noopAsync,
    signUp: noop,
    completeNewPassword: noopAsync,
    updateUserAttribute: noop,
    forgotUserPassword: noop,
    forgotUserPasswordSubmit: noopAsync,
    refreshJWTToken: noopAsync,
})

AuthContext.displayName = 'AuthContext'

// ==============================
// AuthProvider Component
// ==============================

const defaultAuthState = {
    isAuth: false,
    loading: false,
    user: null as null | TUser,
    group: EUserGroup.EMPLOYEE,
}

export const AuthProvider: FC<{
    dispatch: AppDispatch
    children: React.ReactNode
}> = ({ children, dispatch }) => {
    const navigate = useNavigate()
    const { showPreloader } = usePreloader()
    const { shutdown } = useIntercom()

    const [authState, setAuthState] = useState<typeof defaultAuthState>({
        ...defaultAuthState,
        loading: true,
    })
    const [userAttributes, setUserAttributes] = useState<TUserAttributes | null>(null)

    // ==============================
    // Authentication Functions
    // ==============================

    /**
     * Sets user data after successful authentication.
     */
    const setUserData = useCallback(
        async (userData: CognitoUser) => {
            const companyId = userData.attributes['custom:companyId']
            const employeeId = userData.attributes['custom:employeeId']

            // Validate essential attributes
            if (!companyId || !employeeId) {
                await signOut()
                return
            }

            // Construct user object
            const currentUser: TUser = {
                firstName: userData.attributes.name,
                lastName: userData.attributes.family_name,
                email: userData.attributes.email,
                id: userData.username,
                companyId,
                employeeId,
            }

            // Extract custom user attributes
            const currentUserAttributes: TUserAttributes = {
                isCompanyCreated: userData.attributes['custom:isCompanyCreated'] === 'true',
                isProfileConfirmed: userData.attributes['custom:isProfileConfirmed'] === 'true',
            }

            setUserAttributes(currentUserAttributes)

            try {
                const currentSession = await Auth.currentSession()
                const tokenIdJwt = currentSession.getIdToken().getJwtToken()
                const group = await getUserSessionGroup(userData)

                // Store JWT token
                localStorage.setItem(JwtTokenName, tokenIdJwt)

                // Update auth state
                setAuthState({
                    isAuth: true,
                    loading: false,
                    user: currentUser,
                    group: determineUserGroup(group),
                })
            } catch (err) {
                setAuthState(defaultAuthState)
                dispatch(
                    messageActions.messageShown({
                        text: getErrorMessage(err),
                        severity: 'error',
                    }),
                )
            }
        },
        [dispatch],
    )

    /**
     * Handles user sign-in.
     */
    const signIn = useCallback(
        async (email: string, password: string) => {
            showPreloader(true)
            try {
                const userData = await Auth.signIn(email, password)
                if (userData) {
                    if (userData.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        dispatch(
                            messageActions.messageShown({
                                text: 'Please, change your password',
                                severity: 'error',
                            }),
                        )
                    } else {
                        await setUserData(userData)
                        analytics.logEvent(AnalyticsEvents.LOGIN)
                        dispatch(
                            messageActions.messageShown({
                                text: 'Welcome!',
                                severity: 'success',
                            }),
                        )
                    }
                }
            } catch (err) {
                dispatch(
                    messageActions.messageShown({
                        text: getErrorMessage(err),
                        severity: 'error',
                    }),
                )
            } finally {
                showPreloader(false)
            }
        },
        [dispatch, setUserData, showPreloader],
    )

    /**
     * Handles user sign-up.
     */
    const signUp = useCallback(
        async (data: TSignUpData) => {
            try {
                await Auth.signUp(data)
            } catch (err) {
                dispatch(
                    messageActions.messageShown({
                        text: getErrorMessage(err),
                        severity: 'error',
                    }),
                )
                throw err
            }
        },
        [dispatch],
    )

    /**
     * Handles user sign-out.
     */
    const signOut = useCallback(async () => {
        try {
            await Auth.signOut({ global: true })
            shutdown()
            setAuthState(defaultAuthState)
            dispatch(userActions.userDataCleared())
            navigate(ROUTES.HOME)
            analytics.logEvent(AnalyticsEvents.LOGOUT)
        } catch (err) {
            dispatch(
                messageActions.messageShown({
                    text: getErrorMessage(err),
                    severity: 'error',
                }),
            )
        }
    }, [dispatch, navigate, shutdown])

    /**
     * Initiates forgot password process.
     */
    const forgotUserPassword = useCallback(async (email: string) => {
        if (!email) {
            throw new Error('Email cannot be empty')
        }
        // eslint-disable-next-line no-useless-catch
        try {
            await Auth.forgotPassword(email)
            analytics.logEvent(AnalyticsEvents.PASSWORD_RESET)
        } catch (err) {
            throw err
        }
    }, [])

    /**
     * Submits new password after forgot password process.
     */
    const forgotUserPasswordSubmit = useCallback(
        async (email: string, code: string, newPassword: string) => {
            try {
                await Auth.forgotPasswordSubmit(email, code, newPassword)
                await Auth.signOut({ global: true })
                await signIn(email, newPassword)
                navigate(ROUTES.HOME)
            } catch (err) {
                dispatch(
                    messageActions.messageShown({
                        text: getErrorMessage(err),
                        severity: 'error',
                    }),
                )
            }
        },
        [dispatch, navigate, signIn],
    )

    /**
     * Completes new password challenge.
     */
    const completeNewPassword = useCallback(
        async (email: string, tempPassword: string, newPassword: string) => {
            let userData: CognitoUser | undefined
            try {
                userData = await Auth.signIn(email, tempPassword)
            } catch (err) {
                dispatch(
                    messageActions.messageShown({
                        text: 'Invitation link has expired',
                        severity: 'error',
                    }),
                )
                return
            }

            try {
                if (userData?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    const confirmedUser = await Auth.completeNewPassword(userData, newPassword)
                    if (confirmedUser) {
                        const user = await Auth.currentAuthenticatedUser()
                        await setUserData(user)
                    }
                }
            } catch (err) {
                dispatch(
                    messageActions.messageShown({
                        text: getErrorMessage(err),
                        severity: 'error',
                    }),
                )
            }
        },
        [dispatch, setUserData],
    )

    /**
     * Updates user attributes.
     */
    const updateUserAttribute = useCallback(
        async (
            attributeName: keyof TCustomUserAttributes,
            attributeValue: string,
            shortname: keyof TUserAttributes,
        ) => {
            try {
                const user = await Auth.currentAuthenticatedUser()

                if (user && userAttributes) {
                    const updatedAttributes: Partial<TCustomUserAttributes> = {
                        [attributeName]: attributeValue,
                    }

                    await Auth.updateUserAttributes(user, updatedAttributes)

                    setUserAttributes({
                        ...userAttributes,
                        [shortname]: attributeValue === 'true',
                    })
                }
            } catch (err) {
                dispatch(
                    messageActions.messageShown({
                        text: getErrorMessage(err),
                        severity: 'error',
                    }),
                )
            }
        },
        [dispatch, userAttributes],
    )

    /**
     * Refreshes JWT token.
     */
    const refreshJWTToken = useCallback(async () => {
        try {
            const tokenIdJwt = await getNewToken()
            localStorage.setItem(JwtTokenName, tokenIdJwt)
        } catch (err) {
            dispatch(
                messageActions.messageShown({
                    text: 'Unable to refresh Token, ' + getErrorMessage(err),
                    severity: 'error',
                }),
            )
        }
    }, [dispatch])

    // ==============================
    // Data Fetching on Mount
    // ==============================

    const fetchAuthData = useCallback(async () => {
        try {
            const userData = await fetchCurrentUser()

            if (userData) {
                await setUserData(userData)
            } else {
                setAuthState(defaultAuthState)
            }
        } catch (err) {
            setAuthState(defaultAuthState)
            dispatch(
                messageActions.messageShown({
                    text: getErrorMessage(err),
                    severity: 'error',
                }),
            )
        }
    }, [dispatch, setUserData])

    useEffect(() => {
        fetchAuthData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ==============================
    // Context Value Memoization
    // ==============================

    const contextValue = useMemo(
        () => ({
            userAuth: authState.user,
            isAuth: authState.isAuth,
            userGroup: authState.group,
            signIn,
            signUp,
            signOut,
            completeNewPassword,
            userAttributes,
            updateUserAttribute,
            refreshJWTToken,
            forgotUserPassword,
            forgotUserPasswordSubmit,
        }),
        [
            authState.user,
            authState.isAuth,
            authState.group,
            signIn,
            signUp,
            signOut,
            completeNewPassword,
            userAttributes,
            updateUserAttribute,
            refreshJWTToken,
            forgotUserPassword,
            forgotUserPasswordSubmit,
        ],
    )

    // ==============================
    // Loading State
    // ==============================

    if (authState.loading) {
        return (
            <div className="position-center">
                <CircularProgress />
            </div>
        )
    }

    // ==============================
    // Provider Rendering
    // ==============================

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

// ==============================
// Custom Hook for Auth Context
// ==============================

const useAuth = (): TAuthContext => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}

export { useAuth }

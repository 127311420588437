import { SpaceScreenMapPinType, SpaceScreenSeatPin } from 'components/organisms/Reservable/types'
import * as T from 'types/data.types'
import { EAvailabilityType } from 'types/data.types'

import { checkPersonTeamAbilitySeat } from '../conditions'

export const getTypeRepeatSpaceMapPin = ({
    reservable,
    employeeId,
    employeeDepartmentIDs,
    isPersonalSeatAvailableToday,
    isTeamSeatAvailableToday,
    isForToday,
}: {
    reservable: T.SeatPickerReservable
    employeeId: string
    employeeDepartmentIDs: T.MainEmployeeData['departmentIDs']
    isPersonalSeatAvailableToday: boolean
    isTeamSeatAvailableToday: boolean
    isForToday: boolean
}): SpaceScreenSeatPin => {
    const isBlocked = reservable.availabilityType === EAvailabilityType.UNAVALIABLE
    const isUnavailable = checkPersonTeamAbilitySeat(
        reservable,
        employeeId,
        employeeDepartmentIDs,
        isPersonalSeatAvailableToday,
        isTeamSeatAvailableToday,
        isForToday,
    )

    const bookings = reservable?.BookingsByDateAndDayOfWeek?.items || []
    const isFree = bookings.filter((booking) => booking.employeeID !== employeeId).length === 0

    let pinType

    switch (true) {
        case isBlocked:
            pinType = SpaceScreenMapPinType.BLOCKED
            break

        // @ts-ignore
        case isUnavailable && isFree:
            pinType = SpaceScreenMapPinType.UNAVAILABLE_FREE
            break

        // @ts-ignore
        case !isFree:
            pinType = SpaceScreenMapPinType.REPEAT_FULL
            break

        default:
            pinType = SpaceScreenMapPinType.FREE_FULL
            break
    }

    return {
        pinType,
        isOnlyBlockedTime: false,
    }
}

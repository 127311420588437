import { EReservableType } from 'types/data.types'

import { PIN_SIZES, ROOM_SIZES } from './constants'
import { SpaceScreenMapPinType } from '../types'

export const getHeightPinByType = (type: EReservableType) => {
    switch (type) {
        case EReservableType.SEAT:
            return PIN_SIZES.HEIGHT_SEAT_PIN

        case EReservableType.PARKING:
            return PIN_SIZES.HEIGHT_PARKING_PIN

        default:
            return PIN_SIZES.HEIGHT_SEAT_PIN
    }
}

export const getSpaceScreenSeatSize = (typeSeat: SpaceScreenMapPinType) => {
    switch (typeSeat) {
        // case SpaceScreenMapPinType.ONE_BOOKED_FULL:
        // case SpaceScreenMapPinType.MULTI_BOOKED_FULL:
        // case SpaceScreenMapPinType.REPEAT_FULL:
        // case SpaceScreenMapPinType.BLOCKED: {
        //     return {
        //         width: 30,
        //         height: 30,
        //         radius: 15,
        //         avatar: 12,
        //     }
        // }

        default:
            return {
                width: 44,
                height: 44,
                radius: 24,
                avatar: 20,
            }
    }
}

export const getSpaceScreenRoomSize = (typeSeat: SpaceScreenMapPinType) => {
    switch (typeSeat) {
        case SpaceScreenMapPinType.ONE_BOOKED_FULL:
        case SpaceScreenMapPinType.MULTI_BOOKED_FULL:
        case SpaceScreenMapPinType.BLOCKED: {
            return {
                width: 160,
                height: 83,
                radius: 83,
            }
        }

        default:
            return {
                width: 160,
                height: 72,
                radius: 83,
            }
    }
}

export const getSizePreviewHour = (width: number, previewLength: number) => {
    const withHour = (width - ROOM_SIZES.PADDING_ROOM_X * 2) / previewLength
    const offset = withHour / 5
    const radius = (withHour - offset) / 2

    return { radius, offset }
}

import { MenuItem } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const Root = styled.div<{ withOutMargin?: boolean }>`
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 12px;
    width: 100%;
    margin-bottom: ${({ withOutMargin }) => (withOutMargin ? 0 : '24px')};
    margin-top: ${({ withOutMargin }) => (withOutMargin ? 0 : '12px')};
`
export const CustomDropdownContainer = styled.div<{ size?: 'small' | 'medium'; offBackground?: boolean }>`
    border-radius: 12px;
    background: rgba(227, 232, 238, 0.5);

    ${({ offBackground }) =>
        offBackground &&
        css`
            background: rgba(255, 255, 255, 0.5);
        `};

    ${({ size }) => (size === 'small' ? 'height: 40px;' : 'height: 56px;')}

    & .MuiSelect-root {
        padding-right: 65px !important;
    }
    .MuiListItem-root {
        ${({ size }) => (size === 'small' ? 'height: 40px;' : 'height: 56px;')}
    }

    .MuiMenu-list {
        background-color: rgba(227, 232, 238, 255);
    }

    & button {
        cursor: pointer;

        svg {
            transition: 0.2s ease-in-out;
        }
    }

    & div[aria-expanded='true'] ~ button svg {
        transform: rotate(180deg);
    }
`

export const DropDownItem = styled(MenuItem)`
    padding: 14px 8px 13px 16px;

    & * {
        color: ${(props) => props.theme.color.black};
    }
    height: 56px;
    list-style-type: none;
    display: flex;
    align-items: center;
`

import { useCallback, useEffect, useRef, useState } from 'react'

import {
    ApolloError,
    DocumentNode,
    OperationVariables,
    SubscriptionHookOptions,
    TypedDocumentNode,
    useSubscription,
} from '@apollo/client'

const RECONNECT_TIME = 10000
const DELAY_2 = 1000
const DELAY_1 = RECONNECT_TIME - DELAY_2

export function useReconnectingSubscription<TData = any, TVariables extends OperationVariables = OperationVariables>(
    subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: SubscriptionHookOptions<TData, TVariables>,
) {
    const [shouldResubscribe, setShouldResubscribe] = useState(false)
    const timers = useRef<NodeJS.Timeout[]>([])

    const clearTimers = () => {
        timers.current.forEach(clearTimeout)
        timers.current = []
    }

    const reconnect = useCallback(() => {
        clearTimers() // Clear existing timers before setting new ones

        timers.current.push(
            setTimeout(() => {
                console.log('🔌⭐️ Delaying reconnect')
                setShouldResubscribe(true)
            }, DELAY_1),
        )

        timers.current.push(
            setTimeout(() => {
                console.log('🔌⭐️ Reconnecting')
                setShouldResubscribe(false)
            }, DELAY_1 + DELAY_2),
        )
    }, [])

    const onError = useCallback(
        (error: ApolloError) => {
            console.log(`[useReconnectingSubscription] Subscription Failed: ${JSON.stringify(error)}`)
            if (options?.onError) {
                options.onError(error)
            }
            reconnect()
        },
        [options, reconnect],
    )

    const onComplete = useCallback(() => {
        console.log('Subscription completed')
        if (options?.onComplete) {
            options.onComplete()
        }
    }, [options])

    const mergedOptions = {
        ...options,
        skip: options?.skip || shouldResubscribe,
        shouldResubscribe: true,
        errorPolicy: 'ignore',
        onError,
        onComplete,
    }

    const { data, loading, error } = useSubscription<TData, TVariables>(subscription, mergedOptions)

    useEffect(() => {
        // This useEffect hook is only responsible for cleanup
        return () => {
            clearTimers()
        }
    }, []) // Empty dependency array ensures this runs on mount and unmount only

    return { data, loading, error, reconnect }
}
